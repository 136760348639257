<template>
  <div>
    <div v-if="params.column.colDef.field === 'id'">
      <div class="squaredCheckbox">
        <input
          type="checkbox"
          id="squaredCheckboxAll"
          name="check-all"
          @change="handleChk"
          v-model="selected"
        />
        <label for="squaredCheckboxAll"></label>
      </div>
    </div>
    <div class="custom__header" @click="handleSortClick($event)" v-else>
      <div class="custom__header__title">
        <span class="custom__header__title--text">{{ params.displayName }}</span>
        <span
          v-if="params.enableMenu"
          @click="onMenuClicked($event)"
          ref="menuButton"
          data-element="menuButton"
          class="ag-header-icon
          ag-header-cell-menu-button"
          aria-hidden="true"
        >
          <span class="ag-icon ag-icon-menu" unselectable="on" data-element="menuButton"></span>
        </span>
      </div>

      <span
        ref="eSortAsc"
        v-if="params.enableSorting"
        class="ag-header-icon
        ag-sort-ascending-icon"
        aria-hidden="true"
        aria-describedby="button"
      >
        <span v-if="ascSort == 'active'" class="ag-icon ag-icon-asc" unselectable="on"></span>
        <span v-if="descSort == 'active'" class="ag-icon ag-icon-desc" unselectable="on"></span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';

export default {
  data() {
    return {
      ascSort: null,
      descSort: null,
      noSort: null,
      selected: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelected: types.GETTER_SELECTED,
      getOrders: types.GETTER_ORDERS,
      getSelectedAll: types.GETTER_SELECTED_ALL,
    }),
  },
  watch: {
    getSelectedAll: {
      handler(value) {
        if (this.params.column.colDef.field === 'id') {
          this.selected = Boolean(value);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleChk() {
      this.$nextTick(() => {
        this.params.evtFn(this.selected);
      });
    },
    handleSortClick(evt) {
      const { target = {} } = evt;
      const dataAttr = target.getAttribute('data-element') || '';

      if (dataAttr !== 'menuButton' && this.params.enableSorting) {
        if (this.ascSort === 'active') {
          this.onSortRequested('desc', evt);
        } else if (this.descSort === 'active') {
          this.onSortRequested('asc', evt);
        } else {
          this.onSortRequested('asc', evt);
        }
      }
    },
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    onSortChanged() {
      this.ascSort = this.descSort = this.noSort = 'inactive';//eslint-disable-line
      if (this.params.column.isSortAscending()) {
        this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
        this.descSort = 'active';
      } else {
        this.noSort = 'active';
      }
    },
    onSortRequested(order, event) {
      this.setSort(order, event.shiftKey);
    },
  },
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
    if (this.params.column.colDef.field === 'id') {
      this.selected = Boolean(this.getSelectedAll);
    }
  },
};
</script>

<style lang="scss">
.custom__header {
  display: grid;
  grid-template-columns: 90% 10%;
  width: 100%;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    &--text {
      margin-right: 8px;
    }
  }
}
</style>
