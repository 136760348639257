<template>
  <div class="squaredCheckbox">
    <input
      type="checkbox"
      :value="value"
      :id="'squaredCheckbox-' + value"
      :name="'check-' + value"
      v-model="selected"
      @change="updateList"
      :ref="'check_row_' + value"
    />
    <label :for="'squaredCheckbox-' + value"></label>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as types from '@/store/types';

export default {
  name: 'select-cell',
  data() {
    return {
      value: null,
      selected: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelected: types.GETTER_SELECTED,
    }),
  },
  methods: {
    ...mapActions({
      setSelectedOrders: types.ACTION_ORDER_SELECT,
    }),
    updateList() {
      const input = this.$refs[`check_row_${this.value}`];
      const divRow = input.parentElement.parentElement.parentElement;
      divRow.classList.toggle('checkBoxActive');
      this.setSelectedOrders({ id: this.value, insert: this.selected });
    },
  },
  watch: {
    getSelected() {
      this.selected = this.getSelected.includes(this.value);
    },
  },
  mounted() {
    this.value = this.params.value;
    this.selected = this.getSelected.includes(this.value);
  },
};
</script>

<style lang="scss">
.checkBoxActive {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.squaredCheckbox {
  border: none !important;
  width: 20px;
  position: relative;
  margin: 20px auto;
  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: -7px;
    left: -14px;
    border: solid 2px #e63b4f;
    border-radius: 4px;
    &:after {
      content: '';
      width: 12px;
      height: 8px;
      position: absolute;
      top: 2px;
      left: 2px;
      border: 3px solid #e63b4f;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0.3;
    }
  }
  input[type='checkbox'] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}
</style>
