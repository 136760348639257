<template>
  <div class="operation-filter p-2 ag-filter d-flex flex-column" :class="{ active: open }">
    <v-select
      v-model="selected"
      ref="vue-select"
      label="text"
      maxHeight="300px"
      multiple
      :options="options"
      :placeholder="$t('agGrid.filters.selectOperation')"
      :clearable="false"
      :clearSearchOnSelect="false"
      :getOptionKey="option => option.id"
      @search:focus="open = true"
      @search:blur="open = false"
    ></v-select>
    <!-- :reduce="opt => opt.value" -->

    <div
      class="ag-filter-apply-panel p-0 mt-4 d-flex justify-content-between align-items-center"
      ref="eButtonsPanel"
    >
      <uello-button @click="resetFilter" :text="$t('agGrid.localeText.resetFilter')" />
      <uello-button @click="setFilter" :text="$t('agGrid.localeText.applyFilter')" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { safeStorage, createArrayOfUniques } from '@/utils';
import { UelloButton } from '@uello/componentello';

export default {
  name: 'vue-select-status-filter',
  components: {
    UelloButton,
  },
  data() {
    return {
      config: safeStorage.getItem('config'),
      selected: [],
      text: [],
      valueGetter: null,
      options: [],
      open: false,
    };
  },
  beforeMount() {
    this.setOptions();
  },
  created() {
    this.valueGetter = this.params.valueGetter;
  },
  computed: {
    ...mapGetters({
      allOperations: types.GETTER_PARTNER_OPERATIONS,
    }),
  },
  methods: {
    setOptions() {
      const { operations, partner_childs, has_childs } = this.config;

      let newOptions = [...operations];

      if (has_childs && partner_childs && partner_childs.length) {
        newOptions = [
          ...newOptions,
          ...partner_childs.reduce((accumulator, child) => {
            accumulator = [...accumulator, ...child.operations];

            return accumulator;
          }, []),
        ];
      }

      newOptions = createArrayOfUniques(newOptions);

      newOptions = newOptions.map(opt => ({
        value: opt.id,
        text: opt.name,
        operation: opt.type,
      }));

      this.options = [...newOptions];
    },
    setFilter() {
      this.text = this.selected.map(opt => opt.value);
    },
    resetFilter() {
      this.selected = [];
      this.text = [];
      this.setModel('@reset');
    },
    isFilterActive() {
      return (
        this.text !== null && this.text !== undefined && this.text !== '' && !!this.text.length
      );
    },
    doesFilterPass(params) {
      return this.text.find((t, index) => {
        const option = this.getOption(t, this.selected[index].operation);
        return !t || option.text === this.valueGetter(params.node);
      });
    },
    getModel() {
      const operations = [];
      const text = this.text
        ? this.text
            .map((t, index) => {
              operations.push(this.selected[index].operation);
              return this.getOption(t, this.selected[index].operation).text;
            })
            .join(', ')
        : null;

      return {
        value: this.text && this.text.length ? this.text : null,
        text,
        state: true,
        filterType: 'state',
        operations,
      };
    },
    getOption(value, operation = 'delivery') {
      const status = this.options.find(opt => opt.value === value && opt.operation === operation);
      return status;
    },
    setModel(model) {
      if (model !== '@reset' && model !== null) {
        this.text = model.value;
      }

      if (model === '@reset') {
        this.text = [];
        this.selected = [];
      }
    },
  },
  watch: {
    text(val, oldVal) {
      if (val !== oldVal) {
        this.params.filterChangedCallback();
      }
    },
  },
};
</script>

<style lang="scss">
$vs-dropdown-max-height: 300px;
.operation-filter {
  width: 250px;
  transition: height 200ms ease;

  .vs__dropdown-menu {
    max-height: 300px;
  }

  &.active {
    height: 400px;
  }
}
</style>
