<template>
  <div
    class="no-data-overlay rounded p-3 position-absolute d-flex justify-content-center align-items-center"
  >
    <p class="text-secondary font-weight-bold h6 m-0">Sem dados para mostrar</p>
  </div>
</template>

<script>
export default {
  name: 'no-data-overlay',
};
</script>

<style lang="scss">
.no-data-overlay {
  z-index: 999999;
  width: 250px;
  top: calc(50% - 26.5px);
  left: calc(50% - 125px);
  border: 1px solid var(--transition);
}
</style>
