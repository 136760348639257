<template>
  <div class="uello-app-follow h-100 mt-5">
    <!--h2 class="text-dark-darken pl-3 pt-4 mb-4 font-weight-bold page-title">
      {{ $t('pageTitles.follow') }}
    </h2-->
    <follow-ag-grid />
    <transition name="fade" mode="out-in">
      <order-detail
        :order="getOrder"
        @cancel-order="cancelOrder"
        @open-ticket="openMovidesk"
        @closeOrder="closeOrder"
        v-if="haveOrder"
      />
    </transition>
    <movidesk-modal
      :hasButton="movideskHasButton"
      :is-open="movideskIsOpen"
      :order="getOrder"
      :prefill="{}"
      @close="closeMovidesk"
      @open="openMovidesk"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import * as types from '@/store/types';
import api from '@/services/api';
import { getStatusLabel } from '@/utils';
import { movidesk } from '@/mixins';
import MovideskModal from '@/components/MovideskTicketFormModal.vue';
import FollowAgGrid from './components/u-follow/FollowAgGrid.vue';

export default {
  name: 'uello-app-follow',
  mixins: [movidesk],
  data() {
    return {
      showOrderDetail: false,
      showMovidesk: false,
    };
  },
  components: {
    FollowAgGrid,
    MovideskModal,
    OrderDetail: () => import('./components/u-follow/OrderDetail.vue'),
  },
  computed: {
    ...mapGetters({
      getOrder: types.GETTER_ORDER,
      getOrders: types.GETTER_ORDERS,
      modal: types.GETTER_MODAL,
    }),
    confirmed() {
      return this.modal.confirmed;
    },
    haveOrder() {
      return this.getOrder instanceof Object;
    },
  },
  watch: {
    confirmed(val) {
      if (val) {
        this.openMovidesk();
      }
    },
  },
  methods: {
    ...mapMutations({
      setOrder: types.MUTATE_ORDER,
      setOrders: types.MUTATE_ORDERS,
    }),
    cancelOrder(id) {
      api.get(`/orders/${id}`).then(({ data: order }) => {
        const label = getStatusLabel(order.data.state, order.data.operation.type);

        const rowData = this.getOrders.rowData.map(orderData =>
          order.id === id ? { ...orderData, state: label } : orderData
        );

        this.setOrders({ ...this.getOrders, rowData });
      });
    },
    closeOrder() {
      this.closeMovidesk();
      this.setOrder(null);
    },
  },
};
</script>

<style></style>
