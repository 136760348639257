<template>
  <div class="h-100">
    <print-labels style="margin-top:64px" />
    <follow-search-bar
      @export="handleExport"
      @loading="setLoading"
      :visible="rowData !== null && rowData.length > 0"
      :filters="filters"
    />
    <div class="follow-ag-grid px-3 h-100" id="grid-wrapper">
      <filter-list :filters="filters" @resetFilters="resetFilters" @remove="removeFilter" />
      <ag-grid-vue
        class="ag-theme-material rounded"
        rowSelection="single"
        :rowDeselection="true"
        :gridOptions="gridOptions"
        :floatingFiltersHeight="50"
        :groupSuppressBlankHeader="true"
        :animatedRows="true"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :style="{ width: '100%', height: `calc(100% - 260px)` }"
        :localeText="localeText"
        :modules="modules"
        :defaultColDef="defaultColDef"
        :frameworkComponents="frameworkComponents"
        :loadingOverlayComponent="loadingOverlayComponent"
        :loadingOverlayComponentParams="loadingOverlayComponentParams"
        :noRowsOverlayComponent="noRowsOverlayComponent"
        :components="components"
        @grid-ready="onGridReady"
        @grid-size-changed="onGridSizeChanged"
        @filterChanged="getFilters"
        @selection-changed="setRowSelection"
        @cell-focused="onCellFocused"
      ></ag-grid-vue>

      <div
        class="d-flex align-items-center position-relative"
        :class="{ 'mt-3': filters.length > 0, 'mt-4': !filters.length }"
      >
        <uello-pagination
          :color="null"
          :current="currentPage"
          position="left"
          :total="Math.ceil(rows / perPage)"
          @click="handlePagination"
        />

        <div
          class="pagination-options d-flex justify-content-center align-items-center"
          style="margin-left: 30px"
        >
          <span class="text-dark-darken ml-4">
            <small>
              <b>{{ getSelected.length }} {{ $t(`agGrid.localeText.selectedItems`) }}</b>
            </small>
          </span>
          <b-form-select
            v-model="perPage"
            size="sm"
            class=""
            :options="perPageOptions"
            @change="handleLimitChange"
          ></b-form-select>

          <span class="text-dark-darken ml-4">
            <small>
              <b>{{ $t('general.totalRows', { count: getOrders.total }) }}</b>
            </small>
          </span>
        </div>
      </div>
    </div>
    <modal-email-report :filters="filters" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import * as types from '@/store/types';
import { centralWindowSize } from '@/mixins';
import { formatFilters, getFilters, getReverseFilter, defineColumnDefs } from '@/utils/agGrid';
import { UelloPagination } from '@uello/componentello';

import FollowAgGridLoadingOverlay from './FollowAgGridLoadingOverlay.vue';
import NoDataOverlay from './NoDataOverlay.vue';
import VSelectStatusFilter from './VSelectStatusFilter.vue';
import VSelectOperationFilter from './VSelectOperationFilter.vue';
import VSelectPartnerChildsFilter from './VSelectPartnerChildsFilter.vue';
import DateFilter from './DateFilter.vue';
import TextFilter from './TextFilter.vue';
import SelectCell from './SelectCell.vue';
import FollowAgGridHeader from './FollowAgGridHeader.vue';

export default {
  name: 'follow-ag-grid',
  mixins: [centralWindowSize],
  /* eslint-disable vue/no-unused-components */
  components: {
    FollowAgGridLoadingOverlay,
    VSelectStatusFilter,
    VSelectPartnerChildsFilter,
    VSelectOperationFilter,
    NoDataOverlay,
    DateFilter,
    FilterList: () => import('./FilterList.vue'),
    FollowSearchBar: () => import('./FollowSearchBar.vue'),
    PrintLabels: () => import('./PrintLabels.vue'),
    ModalEmailReport: () => import('./ModalEmailReport.vue'),
    TextFilter,
    SelectCell,
    UelloPagination,
    FollowAgGridHeader,
  },
  data() {
    return {
      filters: [],
      disableSelect: false,
      gridOptions: {
        alwaysShowVerticalScroll: true,
        rowStyle: {
          borderColor: 'transparent',
        },
        getRowStyle(params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: 'red' };
          }
          return null;
        },
      },
      gridHeight: 400,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      localeText: null,
      headerHeight: 56,
      paginationPageSize: 5,
      defaultColDef: {
        resizable: true,
        filter: true,
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
      },
      frameworkComponents: null,
      loadingOverlayComponent: null,
      noRowsOverlayComponent: null,
      loadingOverlayComponentParams: null,
      modules: AllCommunityModules,
      currentPage: 1,
      perPage: 15,
      perPageOptions: [
        {
          value: 15,
          text: `15 ${this.$t('form.labels.perPage')}`,
        },
        {
          value: 30,
          text: `30 ${this.$t('form.labels.perPage')}`,
        },
        {
          value: 50,
          text: `50 ${this.$t('form.labels.perPage')}`,
        },
        {
          value: 100,
          text: `100 ${this.$t('form.labels.perPage')}`,
        },
        {
          value: 300,
          text: `300 ${this.$t('form.labels.perPage')}`,
        },
      ],
      rows: 100,
      isFiltered: false,
      allChkTimeout: null,
    };
  },
  created() {
    this.setOrders({});
  },
  beforeMount() {
    this.currentPage = this.getPage;
    this.perPage = this.getLimit;
    this.gridOptions = {
      context: {
        componentParent: this,
      },
    };
    this.localeText = this.$t('agGrid.localeText');
    this.frameworkComponents = {
      customLoadingOverlay: 'FollowAgGridLoadingOverlay',
      customNoRowsOverlay: 'NoDataOverlay',
      vSelectStatusFilter: 'VSelectStatusFilter',
      dateFilter: 'DateFilter',
      textFilter: 'TextFilter',
      vSelectPartnerChildsFilter: 'VSelectPartnerChildsFilter',
      vSelectOperationFilter: 'VSelectOperationFilter',
      agColumnHeader: 'FollowAgGridHeader',
    };
    this.loadingOverlayComponent = 'customLoadingOverlay';
    this.noRowsOverlayComponent = 'customNoRowsOverlay';

    this.components = {
      FollowAgGridLoadingOverlay,
      VSelectStatusFilter,
      VSelectOperationFilter,
      VSelectPartnerChildsFilter,
      TextFilter,
      NoDataOverlay,
    };

    this.columnDefs = defineColumnDefs();

    const checkbox = {
      headerName: '',
      field: 'id',
      resizable: false,
      cellRendererFramework: 'SelectCell',
      cellClass: 'no-border',
      filter: false,
      enableMenu: true,
      colId: 'select-cell',
      width: 60,
      minWidth: 50,
      headerComponentParams: {
        evtFn: this.handleCheck,
      },
    };
    this.columnDefs.unshift(checkbox);
  },
  beforeUpdate() {
    this.checkSelectOrder();
  },
  computed: {
    ...mapGetters({
      getOrders: types.GETTER_ORDERS,
      getOrder: types.GETTER_ORDER,
      getLimit: types.GETTER_LIMIT,
      getPage: types.GETTER_PAGE,
      getError: types.GETTER_ORDERS_ERROR,
      getSelected: types.GETTER_SELECTED,
    }),
  },
  watch: {
    getOrders(value) {
      if (value && value.columnDefs && value.columnDefs.length && value.rowData.length) {
        this.rowData = value.rowData;
        this.rows = value.total;
      } else {
        this.rowData = [];
      }

      this.columnApi.autoSizeColumns();
      this.gridApi.hideOverlay();
    },
    rowData(value) {
      if (!value.length) {
        this.gridApi.hideOverlay();
        this.gridApi.showNoRowsOverlay();
      }
    },
    getOrder(value) {
      if (value) {
        this.gridApi.hideOverlay();
      } else {
        this.gridApi.deselectAll();
      }
    },
    getError(value) {
      if (value === true) {
        this.columnApi.autoSizeColumns();
        this.gridApi.hideOverlay();
      }
    },
    getPage(value) {
      if (value) {
        this.currentPage = value;
      }
    },
  },
  methods: {
    ...mapActions({
      setOrders: types.ACTION_ORDERS_REQUEST,
      setOrder: types.ACTION_ORDER_REQUEST,
      setSelectedOrders: types.ACTION_ORDER_SELECT,
    }),
    ...mapMutations({
      setLimit: types.MUTATE_LIMIT,
      setPage: types.MUTATE_PAGE,
      setSelectedAllOrders: types.MUTATE_SELECTED,
      setSelectedAllCheck: types.MUTATE_ALL_ORDER_SELECTED,
      setSelectedALL: types.MUTATE_SELECTED_ALL,
    }),
    handleCheck(selected) {
      const actualRows = this.rowData.map(r => r.id);
      const filtered = this.getSelected.filter(r => !actualRows.includes(r));

      this.gridApi.showLoadingOverlay();
      this.allChkTimeout = setTimeout(() => {
        if (selected) {
          this.setSelectedALL(actualRows);
        } else {
          this.setSelectedALL(filtered);
        }
        this.gridApi.hideOverlay();
        this.setSelectedAllCheck(selected);
        this.clearAllChkTimeout();
      }, 800);
    },
    clearAllChkTimeout() {
      if (this.allChkTimeout) {
        window.clearTimeout(this.allChkTimeout);
      }
    },
    setTour() {
      this.$tours['follow-tour'].start();
    },
    setLoading(filter) {
      this.gridApi.showLoadingOverlay();
      this.filters = [...this.filters, filter];
    },
    handleLimitChange(limit) {
      this.$store.commit(types.MUTATE_LIMIT, limit);
      this.gridApi.showLoadingOverlay();
      const filters = this.filters.length > 0 ? formatFilters(this.filters) : null;
      this.setOrders({ filters, defs: this.getOrders.columnDefs });
    },
    setHeight() {
      document.querySelector(
        '.ag-theme-material.rounded[animatedrows="true"]'
      ).style.height = `${this.gridHeight}px`;
    },
    setRowSelection() {
      if (this.disableSelect) {
        return;
      }

      const [row] = this.gridApi.getSelectedRows();

      if (row) {
        this.gridApi.showLoadingOverlay();
        this.setOrder(row.id);
      }
    },
    onGridReady({ api, columnApi }) {
      this.gridApi = api;
      this.columnApi = columnApi;
      this.gridApi.showLoadingOverlay();
      api.sizeColumnsToFit();
      this.checkSelectOrder();
    },
    onGridSizeChanged(params) {
      params.api.sizeColumnsToFit();
    },
    handleExport() {
      this.gridApi.exportDataAsCsv();
    },
    handlePagination(page) {
      if (this.gridApi) {
        this.gridApi.showLoadingOverlay();
      }
      this.$store.commit(types.MUTATE_PAGE, page);
      const filters = this.filters.length > 0 ? formatFilters(this.filters) : null;
      this.setOrders({ filters, defs: this.getOrders.columnDefs });
      this.checkSelectOrder();
    },
    resetFilters() {
      window.location.reload();
    },
    getFilters(event) {
      if (Object.keys(event.api.filterManager.allFilters).length) {
        this.filters = getFilters(
          event,
          this.filters.filter(f => f.value !== null && f.value.length)
        );
        this.gridApi.showLoadingOverlay();
        const filters = this.filters.length > 0 ? formatFilters(this.filters) : null;
        this.filtered = true;
        this.$store.commit(types.MUTATE_PAGE, 1);
        this.setOrders({ filters, defs: this.getOrders.columnDefs });
      }
    },
    removeFilter(field) {
      this.gridApi.showLoadingOverlay();
      this.filters = this.filters.filter(f => f.field !== field);

      if (this.gridApi.getFilterInstance(getReverseFilter(field))) {
        this.gridApi
          .getFilterInstance(getReverseFilter(field))
          .setModel(field === 'status' || field === 'partner' ? '@reset' : null);
      }

      this.setOrders({
        filters: this.filters.length > 0 ? formatFilters(this.filters) : null,
        defs: this.getOrders.columnDefs,
      });
    },
    onCellFocused(event) {
      if (event.column == null) {
        return;
      }

      this.disableSelect = event.column.colId === 'select-cell';
    },
    checkSelectOrder() {
      const a = this.getOrders.rowData.map(row => row.id);
      const b = this.getSelected;
      const compare = a.every(v => b.includes(v));
      this.setSelectedAllCheck(compare);
    },
  },
};
</script>

<style lang="scss">
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

.follow-ag-grid {
  span[ref='eMenu'] {
    opacity: 1 !important;
  }

  .pagination-options {
    right: 0;
    transform: translateY(-5px);

    span,
    select {
      display: block;
      min-width: 160px;
    }
  }

  .ag-menu {
    max-height: calc(100% - 46px);
  }

  .ag-theme-material .ag-row {
    cursor: pointer;
  }

  .ag-theme-material .ag-input-wrapper input:not([type]):focus,
  .ag-theme-material .ag-input-wrapper input[type='text']:focus,
  .ag-theme-material .ag-input-wrapper input[type='number']:focus,
  .ag-theme-material .ag-input-wrapper input[type='tel']:focus,
  .ag-theme-material .ag-input-wrapper input[type='date']:focus,
  .ag-theme-material .ag-input-wrapper input[type='datetime-local']:focus {
    border-color: var(--primary);
  }

  .ag-theme-material .ag-row:not(.ag-row-first .ag-row-selected) {
    border: 0;

    &:nth-child(even):not(.ag-row-selected) {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  .ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
  .ag-theme-material .ag-ltr .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
  .ag-theme-material .ag-rtl .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
    border: 0;
  }

  .ag-theme-material .ag-filter .ag-filter-apply-panel {
    display: flex;
    justify-content: space-between;
    button {
      color: #fff;
      font-weight: bold;
      border: 1px solid var(--primary);
      padding: 2px 6px;
      border-radius: 0.25rem;
      transition: all 200ms ease;
      margin: 0;
      background-color: var(--primary);

      &:nth-child(3n) {
        margin-left: 1rem;
      }

      &:hover {
        background-color: #fff;
        color: var(--primary);
      }
    }
  }

  .ag-theme-material .ag-row-selected {
    background-color: rgba(0, 0, 0, 0.15);
    /* background-color: var(--primary);
    color: #fff; */
  }

  .ag-theme-material .ag-icon {
    color: #fff;
    background-color: var(--primary);
    border-radius: 0.25rem;
    padding: 2px;
  }

  .ag-header-cell-label .ag-header-cell-text {
    font-weight: bold;
    text-transform: uppercase;
  }

  .ag-root.ag-layout-normal {
    border-radius: 0.25rem;
  }
  .ag-root-wrapper-body {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .data-grid {
    width: 100%;
    height: 500px;
  }
}
</style>
